// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-estudio-js": () => import("./../../../src/pages/estudio.js" /* webpackChunkName: "component---src-pages-estudio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-proyectos-js": () => import("./../../../src/pages/proyectos.js" /* webpackChunkName: "component---src-pages-proyectos-js" */),
  "component---src-pages-reportajes-js": () => import("./../../../src/pages/reportajes.js" /* webpackChunkName: "component---src-pages-reportajes-js" */),
  "component---src-templates-proyecto-js": () => import("./../../../src/templates/proyecto.js" /* webpackChunkName: "component---src-templates-proyecto-js" */),
  "component---src-templates-reportaje-js": () => import("./../../../src/templates/reportaje.js" /* webpackChunkName: "component---src-templates-reportaje-js" */)
}

